@import 'var';

* {
  box-sizing: border-box; }

body,
html {
  background-color: $prime;
  font-size: 10px;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%; }

header {
  background: $white;
  border-bottom: 1px solid $prime;
  left: 0;
  padding: 2rem 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: 200ms;
  z-index: 1;

  .container {
    height: 100%; }

  .row {
    flex-direction: column-reverse;
    height: 100%;
    text-align: center;

    @media screen and (min-width: 768px) {
      flex-direction: row; }

    .col-md-8 {

      @media screen and (min-width: 768px) {
        justify-content: start !important; } }

    .col-md-4 {
      height: 100%;
      @media screen and (min-width: 768px) {
        text-align: end; } }

    .logo {
      transition: 200ms; }

    h1 {
      color: $prime;
      font-family: 'Inknut Antiqua', serif;
      font-size: 2rem; } } }

.scrolldown {
  height: 50px;
  padding: .5rem 0;
  transition: 500ms;

  .col-md-8 {
    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: start; }
    h1 {
      display: none; } }

  .col {
    text-align: center; }

  .logo {
    height: 40px;
    margin: 0 auto; } }

.siteid-home {
  header {
    .row {
      flex-direction: row;
      justify-content: space-between;
      text-align: left; }

    .col-8 {
      align-items: center;
      display: flex; }

    .col {
      text-align: right; }

    img {
      height: 60px; }

    a {
      &:hover {
        text-decoration: none; } }
    .container {
      @media screen and (min-width: 768px) {
        display: flex;
        justify-content: flex-end; } } }



  .scrolldown {
    h1 {
      font-size: 1.6rem;
 } }      //display: none

  main {
    font-size: 1.6rem;
    margin-top: 119px;
    @media screen and (min-width: 421px) {
      margin-top: 100px; }

    h3 {
      margin-top: 2em;
      text-align: center;
      @media screen and (min-width: 768px) {
        text-align: left; } }

    .container {
      text-align: left; }

    .row {
      flex-direction: column;
      flex-wrap: nowrap;
      @media screen and (min-width: 895px) {
        flex-direction: row-reverse;
        margin-top: 2em; } }

    .col-md-9 {
      @media screen and (min-width: 895px) {
        border-left: 1px solid $lgrey; }

      h3.top {
        margin-top: 0; }

      .presse {
        text-align: center;
        @media screen and (min-width: 895px) {
          text-align: left; } }

      svg {
        margin-bottom: .2em; } } }

  .main-sorb {
    @media screen and (min-width: 392px) {
      margin-top: 100px; } } }




main {
  background: $white;
  margin-top: 29.7rem;
  padding-bottom: 5rem;
  padding-top: 5rem;
  @media screen and (min-width: 768px) {
    margin-top: 19.6rem; }

  figcaption {
    text-align: end;
    font-size: 1rem; }

  img {
    max-width: 100%; }
  .container {
    font-size: 1.6rem;
    text-align: -webkit-center;

    @media screen and (min-width: 769px) {
      display: flex;

      .col-12 {
        align-items: center;
        display: flex;
        justify-content: center; } } } }


.intro-lang {
  a {
    font-size: 2em;
    margin: 1em 0;
    padding: 1em;

    img {
      margin-right: 1em; } } }

aside {
  padding: 0 1em;

  @media screen and (min-width: 769px) {
    padding-right: 2em; }

  .card {
    box-shadow: 0 5px 10px rgba(0, 0, 0, .19), 0 3px 3px rgba(0, 0, 0, .23);
    margin-bottom: 2rem;

    .card-link {
      color: $prime;
      &:hover {
        color: $white; } }

    a {
      color: $white;

      &:hover {
        text-decoration: none; } }



    .card-body {
      text-align: right;

      p {
        text-align: left; } }

    svg {
      margin-right: .4em;
      margin-bottom: .2em; } }

  .card-header {
    padding: .75rem .5rem;

    article {
      p {
        text-indent: 1em; } }

    .row {
      margin: 0; } } }

footer {
  background: $prime;
  font-size: 1.2rem;
  padding: 1rem 1rem 2rem;

  .impressum {
    margin-bottom: 1rem;
    text-align: center;

    a {
      color: $white;
      text-decoration: underline; } }

  .banner {
    background: $white;
    margin: 0 auto;
    padding: 1rem;
    width: fit-content;

    img {
      max-width: 100%; } }

  .modal {
    font-size: 1.6rem; }

  .datenschutz {
    p {
      text-indent: 1em; } } }
