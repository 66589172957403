@font-face {
  font-family: 'Inknut Antiqua';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/inknut-antiqua-v5-latin-regular.woff2') format('woff2'), url('../fonts/inknut-antiqua-v5-latin-regular.woff') format('woff'); }

$prime: rgb(0, 107, 101);
$second: rgb(12, 16, 22);
$white: rgb(255, 255, 255);
$lgrey:  rgba(0, 0, 0, .125);
$font: 'Inknut Antiqua', serif;

h3 {
  font-family: $font;
  font-weight: bold;
  margin-bottom: 2rem; }

.btn-success,
.btn-outline-success {
  color: $prime; }
